
























import Vue, { PropType } from 'vue';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';

import BuildInfo from '@devhacker/shared/features/BuildInfo/BuildInfo.vue';
import NavMenuCategories from './NavMenuCategories.vue';

import NavMenuSocials from './NavMenuSocials.vue';

import orientationChange from '~/directives/orientationChange';
import { Menu } from '~/store/menus';
import MascotBurger from '@/components/Mascot/MascotBurger.vue';
import { isHealthPath } from '~/router';
import { isProductionStand } from '~/constants/config';

export default Vue.extend({
  directives: {
    orientationChange,
  },

  components: {
    NavMenuSocials,
    NavMenuCategories,
    PerfectScrollbar,
    MascotBurger,
    BuildInfo,
  },

  props: {
    burgerMenuItems: {
      type: Array as PropType<Array<MenuItem>>,
      required: true,
    },
    socialNetwork: {
      type: Object as PropType<Menu>,
      required: true,
    },
    isNavVisible: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    hideNav: {
      type: Function as PropType<() => void>,
      required: true,
    },
    isNavMenuVisibleDesktop: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },

  data () {
    return {
      isMascotClosed: false as Boolean,
    };
  },

  computed: {
    isProductionStand: () => isProductionStand,

    isHealth (): boolean {
      return isHealthPath(this.$route.path);
    },

    cssClasses (): { [propName: string]: boolean } {
      return {
        'nav-menu--visible': this.isNavVisible,
        'nav-menu--hidden': !this.isNavVisible,
      };
    },

    socialTitle () {
      return this.socialNetwork?.title || 'Мы в соцсетях';
    },

    socialItems () {
      return this.socialNetwork?.items ?? [];
    },
  },

  watch: {
    isNavMenuVisibleDesktop (newVal: boolean): void {
      if (newVal) {
        this.updatePerfectScrollbar();
      }
    },

    isNavVisible (newVal: boolean): void {
      if (newVal) {
        this.updatePerfectScrollbar();
      }
    },
  },

  methods: {
    onOrientationChange ({ orientation }: any): void {
      document.body.style.overflow =
        orientation === 'portrait' && this.isNavVisible ? 'hidden' : 'auto';
    },

    updatePerfectScrollbar () {
      // Если элемент изначально не отображается, то скроллбар не может рассчитать свои размеры.
      // Поэтому при появлении элемента на экране нужно вызвать метод update.
      // См issue https://github.com/mdbootstrap/perfect-scrollbar/issues/476
      // и документацию библиотеки perfect-scrollbar
      try {
        // @ts-ignore
        this.$refs.perfectScrollbar.update();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(error);
      }
    },

    handleCloseMascot () {
      this.isMascotClosed = true;

      this.$nextTick(() => {
        this.updatePerfectScrollbar();
      });
    },
  },
});
